import './application';

import $ from 'utils/jquery';

$.router.init(
    // Reload root page if root page is empty
    window.addEventListener('#modal-window hidden.bs.modal', function (event) {
        if (window.location.href.includes('/report') && $('#vin-search').length === 0) {
            // Reload list of vehicles not preloaded
            window.location = '/'
        }
        else if (window.location.href.includes('/report') && $('#vin-search').length > 0) {
            $("#url").attr('data-url-new-url', "/");
            $("#url").attr('data-url-refresh-value', 1);
        }
    })
)
